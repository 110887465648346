// Feed Carousel

let sliderContainer = document.querySelector(".feed-slider-container");
let innerSlider = document.querySelector(".feed-slider-inner");

let pressed = false;
let startX;
let x;

sliderContainer.addEventListener("mousedown", handleStart);
sliderContainer.addEventListener("touchstart", handleStart);
sliderContainer.addEventListener("mouseenter", () => {
  sliderContainer.style.cursor = "grab";
});
sliderContainer.addEventListener("mouseleave", () => {
  sliderContainer.style.cursor = "default";
});
sliderContainer.addEventListener("mouseup", handleEnd);
window.addEventListener("mouseup", handleEnd);
sliderContainer.addEventListener("mousemove", handleMove);
sliderContainer.addEventListener("touchmove", handleMove);

function handleStart(e) {
  pressed = true;
  if (e.type === "mousedown") {
    startX = e.clientX - innerSlider.offsetLeft;
  } else if (e.type === "touchstart") {
    startX = e.touches[0].clientX - innerSlider.offsetLeft;
  }
  sliderContainer.style.cursor = "grabbing";
}

function handleEnd() {
  sliderContainer.style.cursor = "grab";
  pressed = false;
}

function handleMove(e) {
  if (!pressed) return;
  e.preventDefault();
  if (e.type === "mousemove") {
    x = e.clientX;
  } else if (e.type === "touchmove") {
    x = e.touches[0].clientX;
  }
  innerSlider.style.left = `${x - startX}px`;
  checkBoundary();
}

const checkBoundary = () => {
  let outer = sliderContainer.getBoundingClientRect();
  let inner = innerSlider.getBoundingClientRect();

  if (parseInt(innerSlider.style.left) > 0) {
    innerSlider.style.left = "0px";
  }

  if (inner.right < outer.right) {
    innerSlider.style.left = `-${inner.width - outer.width}px`;
  }
};

// Témoignages Carousel
const slidesContainer = document.getElementById("temoignages-slides-container");
const slide = document.querySelector(".slide");
const prevButton = document.getElementById("slide-arrow-prev");
const nextButton = document.getElementById("slide-arrow-next");

nextButton.addEventListener("click", () => {
  const slideWidth = slide.clientWidth;
  const totalWidth = slidesContainer.scrollWidth;
  const currentPosition = slidesContainer.scrollLeft;
  const remainingWidth = totalWidth - currentPosition;

  if (remainingWidth === slidesContainer.clientWidth) {
    slidesContainer.scrollLeft = 0; // Retourne au premier élément
  } else {
    slidesContainer.scrollLeft += slideWidth;
  }
});

prevButton.addEventListener("click", () => {
  const slideWidth = slide.clientWidth;
  const currentPosition = slidesContainer.scrollLeft;

  if (currentPosition === 0) {
    slidesContainer.scrollLeft = slidesContainer.scrollWidth; // Retourne au dernier élément
  } else {
    slidesContainer.scrollLeft -= slideWidth;
  }
});

const endpoint = "https://exemple.com/me";
const fields = "champ1,champ2"; // Remplace avec les champs que tu veux récupérer
const accessToken = "ton-access-token"; // Remplace avec ton access token

// Construire l'URL avec les paramètres
const url = `https://graph.instagram.com/me/media?fields=id,caption, media_url,timestamp,media_type,permalink&access_token=IGQWRNOUhjc09DTXhoSDdicnpPQ1lCeDhqeFJKby1JR0RDQjlobC1pX0NJV1NFOFp2c0hPYksyM1J0V1huenFVZATBndXJVMWhQcmd0RENvaWNQdnNkRjhlT2MwMEphdzg3YlRUTEVIcTZAvNVdNSUZAwa1JEdTRTOG8ZD`;

// Instagram API
fetch(url)
  .then((response) => {
    if (!response.ok) {
      throw new Error(`Erreur HTTP! Statut: ${response.status}`);
    }
    return response.json();
  })
  .then((data) => {
    data.data.forEach((result) => {
      if (
        result.media_type === "CAROUSEL_ALBUM" ||
        result.media_type === "IMAGE"
      ) {
        const imageContainer = document.getElementById(
          "feed-slider-img-generator"
        );

        const cardDiv = document.createElement("div");
        cardDiv.classList.add("card");

        const imageUrl = result.media_url;

        // Créer un élément d'image
        const imgElement = document.createElement("img");
        imgElement.src = imageUrl;

        imgElement.height = 400;

        cardDiv.appendChild(imgElement);

        const captionParagraph = document.createElement("p");
        captionParagraph.textContent = result.caption;

        // Ajouter la balise <p> à la div "card"
        // cardDiv.appendChild(captionParagraph);

        imageContainer.appendChild(cardDiv);
      }
    });
  })
  .catch((error) => {
    console.error("Erreur lors de la requête:", error);
  });
